.gg-container {
  --main-color: #000;
  --secondary-color: #111;
  --txt-color: #fff;
  --img-bg-color: rgba(240, 240, 240, 0.9);
  --backdrop-color: rgba(240, 240, 240, 0.9);
  --gap-length: 2px;
  --row-height: 200px;
  --column-width: 220px;
}

.gg-container *[data-theme="dark"] {
  --main-color: #ddd;
  --secondary-color: #eee;
  --txt-color: #111;
  --img-bg-color: rgba(20, 20, 20, 0.9);
  --backdrop-color: rgba(30, 30, 30, 0.9);
}

.gg-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--column-width), 1fr));
  grid-auto-rows: var(--row-height);
  grid-gap: var(--gap-length);
  margin: 20px 0;
}

.gg-box img {
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: var(--img-bg-color);
}

.gg-box img:hover {
  opacity: 0.98;
}

#gg-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--backdrop-color);
  z-index: 9999;
  text-align: center;
}

#gg-screen .gg-image {
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

#gg-screen .gg-image img {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.gg-btn {
  width: 35px;
  height: 35px;
  background: var(--main-color);
  color: var(--txt-color);
  text-align: center;
  line-height: 35px;
  cursor: pointer;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 20px;
  box-sizing: border-box;
  padding-left: 2px;
  position: fixed;
  bottom: 10px;
}

.gg-btn:hover {
  background: var(--secondary-color);
}

.gg-close {
  top: 10px;
}

.gg-close,
.gg-next {
  right: 10px;
}

.gg-prev {
  right: 50px;
}

.gg-prev,
.gg-next {
  bottom: 10px;
}

@media (min-width: 478px) {
  .gg-box img:nth-child(2n):not(:last-of-type) {
    grid-row-end: span 2;
  }

  [data-layout="horizontal"] img:nth-child(2n):not(:last-of-type) {
    grid-column-end: span 2;
    grid-row-end: span 1;
  }

  [data-layout="square"] img:nth-child(2n):not(:last-of-type) {
    grid-row-end: span 1;
    grid-column-end: span 1;
  }
}

@media (max-width: 768px) {
  .gg-box {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: calc(var(--row-height) - 15vh);
    margin: 10px 0;
  }
}

@media (max-width: 450px) {
  .gg-box {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}
