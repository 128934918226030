/*#region Global*/

:root {
    --primary-color: #c42931;
    --primary-color-brighter: #ff0046;
    --secondary-color: #fefbd3;
    --background-dark-color: #361f1f;
    --background-dark-color-lighter: #543030;
    --text-over-background-dark: #c5c5c5;
    --subtitle-color: #757575;
    --text-color: #292929;
    --page-width: 45rem;
    --page-width-homepage: 70rem;
    --nav-height: 6rem;
    --deactivated-color: #596476;
    --alt-color: #f3f4f7;
    --logo-and-hamburger-horizontal-margin: 5rem;
    --footer-color: #361f1f;
    --header-color: white;
    --header-text-color: black;
    --image-border-radius: 0.5rem;
}

html {
    /* Per rem */
    font-size: 17px;
    scroll-behavior: smooth;
}

body {
    background-color: white;
}

ol {
    margin: 0;
}

/* Font
-------------------------------------------------- */

/* inter-300 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: local(''), url('../fonts/inter-v3-latin-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/inter-v3-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-regular - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local(''), url('../fonts/inter-v3-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/inter-v3-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-700 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: local(''), url('../fonts/inter-v3-latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/inter-v3-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-900 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: local(''), url('../fonts/inter-v3-latin-900.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/inter-v3-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
        Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
}

b,
strong {
    font-weight: 700;
    color: var(--primary-color);
}

/** Reset */

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
hr {
    margin: 0;
    padding: 0;
}

*:focus {
    outline: none;
}

/*#endregion*/

/*#region Header */

p,
li {
    font-weight: 400;
    color: var(--text-color);
    font-size: 1.2rem;
    word-break: break-word;
    word-wrap: break-word;
    padding: 1rem 0;
}

ul {
    padding: 1rem 2.5rem;
}

ul li {
    padding: 0.2rem 0;
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--header-color);
    color: var(--header-text-color);
    z-index: 2;
}

.top-contact-bar {
    background-color: var(--primary-color-brighter);
    padding: 0.6rem var(--logo-and-hamburger-horizontal-margin);
    display: flex;
    align-items: center;
    flex-direction: row;
    color: white;
}

.top-contact-bar :first-child {
    flex: 1;
}

.top-contact-bar * {
    color: white;
    text-decoration: none;
}

.header-content {
    display: flex;
    align-self: center;
    margin: 0 auto;
    height: var(--nav-height);
    align-items: center;

    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.header-content img {
    margin-left: var(--logo-and-hamburger-horizontal-margin);
}

/*#endregion*/

#top-nav {
    flex: 1;
}

#top-nav ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

#top-nav ul li {
    line-height: normal;
}

#top-nav ul li a,
.root-menu-item {
    color: var(--header-text-color);
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1rem;
    margin-left: 2rem;
    transition: all ease-in-out 0.1s;
    cursor: pointer;
}

.root-menu-item {
    display: inline;
    box-sizing: border-box;
}

#top-nav ul li a:hover,
.current-page,
.root-menu-item:hover {
    padding-bottom: 4px;
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color) !important;
    transition: all ease-in-out 0.1s;
}

#top-nav ul li ul {
    background: white;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    display: none;
    padding: 0;
    margin-top: 0.5rem;
    box-shadow: 0px 0px 37px 0px rgba(0, 0, 0, 0.5);
}

#top-nav ul li ul li {
    padding: 0.5rem 0;
}

#top-nav ul li ul li a {
    margin: 0.2rem 0;
    padding: 1rem;
    width: 100%;
}

#top-nav ul li ul li:hover {
    background-color: var(--primary-color);
    transition: all ease-in-out 0.2s;
}

#top-nav ul li ul li:hover a {
    color: white !important;
    transition: all ease-in-out 0.2s;
    border-bottom: 0;
}

#top-nav ul li:hover .root-menu-item ul,
  #top-nav ul li:focus-within .root-menu-item ul, /* this is the line we add */
  #top-nav ul li .root-menu-item ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
}

#top-nav ul li ul li {
    clear: both;
    width: 100%;
}

#hamburger-menu-button {
    display: none;
}

#top-nav {
    display: block;
}

/*#region Hamburger Menu */

#overlay-menu-button {
    position: fixed;
    right: var(--logo-and-hamburger-horizontal-margin);
    top: 1.4rem;
    padding: 16px 11px;
    z-index: 5;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
}

#overlay-menu-text {
    color: var(--header-text-color);
    font-style: normal;
    margin-right: 0.5rem;
}

#overlay-menu-text:after {
    content: 'Menu';
}

#overlay-menu-button span {
    height: 4px;
    width: 35px;
    border-radius: 2px;
    background-color: var(--header-text-color);
    position: relative;
    display: block;
    transition: all 0.2s ease-in;
}

#overlay-menu-button span:before {
    top: -10px;
    visibility: visible;
}

#overlay-menu-button span:after {
    top: 10px;
}

#overlay-menu-button span:before,
#overlay-menu-button span:after {
    height: 4px;
    width: 35px;
    border-radius: 2px;
    background-color: var(--header-text-color);
    position: absolute;
    content: '';
    transition: all 0.2s ease-in;
}

#overlay-menu-button:hover span,
#overlay-menu-button:hover span:before,
#overlay-menu-button:hover span:after {
    color: var(--primary-color);
    background-color: var(--primary-color);
}

#overlay-menu-button:hover em {
    color: var(--primary-color);
}

#overlay-menu-input {
    display: none;
}

#overlay-menu-input:checked ~ #overlay-menu {
    height: 100vh;
    visibility: visible;
    transition: all 0.2s cubic-bezier(0.18, 0.4, 0.56, 0.83);
}

#overlay-menu-input:checked ~ #overlay-menu-button em:after {
    content: 'Chiudi';
}

#overlay-menu-input:checked ~ #overlay-menu ul li {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.4s cubic-bezier(0.18, 0.4, 0.56, 0.83);
}

#overlay-menu ul {
    padding: 0;
}

#overlay-menu-input:checked ~ #overlay-menu-button{
    top: 0;
}

#overlay-menu-input:checked ~ #overlay-menu-button:hover span,
#overlay-menu-input:checked ~ #overlay-menu-button span {
    background: transparent;
}

#overlay-menu-input:checked ~ #overlay-menu-button span:before {
    transform: rotate(45deg) translate(7px, 7px);
    opacity: 1;
}

#overlay-menu-input:checked ~ #overlay-menu-button span:after {
    transform: rotate(-45deg) translate(7px, -7px);
}

#overlay-menu {
    height: 0vh;
    width: 100vw;
    background: white;
    z-index: 2;
    visibility: hidden;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-out;
}

#overlay-menu.active {
    visibility: visible;
}

#overlay-menu ul {
    text-align: center;
    padding-left: 0;
    list-style-type: none;
}

#overlay-menu ul li {
    padding: 0.4rem;
    width: 560px;
    text-align: center;
    transform: translateY(25px);
    opacity: 0;
    transition: transform 0.3s ease-in-out;
}

#overlay-menu ul li a {
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.8rem;
    transition: transform 0.1s ease-in-out;
}

#overlay-menu ul li a:hover {
    color: var(--primary-color);
    transition: all 0.1s ease-in-out;
    padding-bottom: 4px;
    border-bottom: 2px solid var(--primary-color);
}

.contact-button-phone-small {
    display: none !important;
}

.button-scarica-contatto-small {
    display: none !important;
}

.overlay-sublinks-title {
    color: grey;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.5rem;
}

.overlay-sublinks-list {
    margin: 0;
    padding: 0;
}

/*#endregion*/
/*#region Footer */

footer ul,
footer li {
    list-style: none;
}

footer ul {
    display: flex;
    flex-direction: row;
}

footer {
    background: var(--footer-color);
    line-height: 2rem;
    font-size: 0.9rem;
    padding: 2rem;
}

footer p:first-child {
    color: var(--text-over-background-dark);
    text-align: center;
    padding: 0.4rem 0 0 0;
    font-size: 0.9rem;
}

footer p:nth-child(2) {
    color: var(--text-over-background-dark);
    text-align: center;
    padding: 0 0 0.4rem 0;
    font-size: 0.9rem;
}

.footer-infos-container {
    display: flex;
    flex-direction: column;
}

.footer-infos-container ul {
    margin: 0;
    padding: 0;
}

.footer-infos-container ul li {
    margin-right: 1rem;
}

.single-image-inside-article {
    display: flex;
    justify-content: center;
}

.bottom-menu-section {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    font-weight: 300;
}

.bottom-menu-section > * {
    margin: 0px 2.6rem;
}

.bottom-menu-section img {
    height: 120px;
}

.last-footer-section {
    background-color: var(--footer-color);
    font-weight: 300;
}

.last-footer-section p {
}

.pipe {
    margin: 0 0.4rem;
}

/*#endregion*/

/** Articoli */

section {
    width: 100%;
    max-width: var(--page-width);
    padding: 3rem 0;
}

.gallery-section {
    max-width: none;
    padding: 2rem 0.2rem;
}

.gallery-section img {
    border-radius: var(--image-border-radius);
}

section img {
    max-width: 500px;
    max-height: 400px;
    border-radius: var(--image-border-radius);
    margin: 1rem 0;
}

.common-section {
    margin: 1rem 0;
    max-width: var(--page-width);
    line-height: 1.7rem;
    width: 100%;
}

.section-content-landing p {
    padding: 0.6rem 0;
    font-size: 1.1rem;
}

.outside-link,
.colored-link {
    text-decoration: none !important;
    color: var(--background-dark-color);
    border-bottom: 3px solid var(--primary-color);
}

.outside-link:hover,
.colored-link:hover {
    color: var(--primary-color);
}

.download-my-vcard {
    margin-top: 1rem !important;
}

.screenshot-with-border {
    border: 1px solid var(--text-over-background-dark);
    border-radius: 10px;
    padding: 10px;
}

.image-paragraph-bigger img {
    max-width: var(--page-width);
}

.intermediate-article-image {
    max-width: 500px;
    width: 100%;
    margin-top: 1rem;
    border-radius: 40px !important;
    height: auto;
    margin-bottom: 1rem;
}

.left-image {
    float: left;
    margin-right: 2rem;
}

.right-image {
    float: right;
    margin-left: 2rem;
}

.vertical-image {
    max-width: 300px !important;
}

.vertical-image-small {
    max-width: 150px !important;
}

.vertical-image-medium {
    max-width: 200px !important;
}

/** Contenuto principale */

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--nav-height);
}

.main-homepage .fullscreen-section-content {
    max-width: var(--page-width-homepage);
}

h1 {
    font-size: 2.4rem;
    font-weight: 900;
}

h2 {
    font-size: 1.4rem;
    color: var(--subtitle-color);
    font-weight: normal;
}

h3 {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 600;
    color: var(--primary-color);
}

h4 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}

.contactNotFullScreenSection {
    background-color: var(--primary-color);
    border-radius: 3rem;
    max-width: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
}

.vertical-buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.vertical-buttons > * {
    max-width: 500px;
    width: 100%;
}

.contact-button-less-important {
    margin-top: 0.5rem !important;
}

.contact-button {
}

.contactNotFullScreenSection > * {
    text-align: center;
}

.contactNotFullScreenSection .action-button {
    background-color: white;
    color: black;
}

.contactNotFullScreenSection .action-button i {
    color: black;
}

.contactNotFullScreenSection .action-button:hover {
    background-color: white;
}

.contact-title {
    font-size: 2rem;
    color: white;
}

.contacts-description {
    color: var(--secondary-color);
}

label {
    font-weight: 700;
}

.grey-section {
    background-color: var(--background-dark-color);
}

.grey-section > * {
    color: white;
    text-align: center;
}

.homepage-section {
    background-color: var(--primary-color);
    max-width: none;
}

/*.coloured-header {
    background-image: linear-gradient(to right, var(--gradient-first-color) 0%, var(--gradient-second-color) 51%, var(--gradient-third-color) 100%);
}*/

.small-card {
    margin-bottom: 0.5rem !important;
    padding: 1rem 1rem !important;
    background-color: white;
}

.card {
    padding: 2rem;
    box-shadow: 0 0 40px -15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 2rem;
    transition: all 0.45s ease;
    cursor: pointer;
}

.card:hover {
    transition: all 0.45s ease;
    background-color: var(--gradient-second-color);
}

.card:hover .autorizzazioni-item-content p {
    color: black;
}

.card:hover .autorizzazioni-item-content-right-arrow {
    font-size: 1rem;
    transition: all 0.45s ease;
    font-weight: 700;
    color: black;
}

.card:hover .autorizzazioni-item-content-right-arrow i {
    font-size: 1.1rem;
    transition: all 0.45s ease;
    font-weight: 700;
    color: black;
}

.autorizzazioni-item-content p {
    color: var(--subtitle-color);
}

.autorizzazioni-item-content-right-arrow i {
    transition: all 0.45s ease;
    font-size: 1rem;
    height: 1.3rem;
}

.autorizzazioni-item-content-right-arrow {
    transition: all 0.45s ease;
    font-size: 0.9rem;
    align-self: flex-end;
    color: var(--subtitle-color);
    display: flex;
    align-items: center;
}

.card a {
    text-decoration: none;
}

.autorizzazioni-item-content {
    display: flex;
    flex-direction: row;
}

.autorizzazioni-item-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.open-source-project-small-card-item-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: center;
}

.open-source-project-small-card-item-content .autorizzazioni-item-content-title {
    flex: 1;
}

.autorizzazioni-item-content-title h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.open-source-project-small-card-item-content .autorizzazioni-item-content-right-arrow {
    align-self: center;
}

.autorizzazioni-item-content img,
.open-source-project-small-card-item-content img {
    max-width: 60px;
    border-radius: 50%;
}

.autorizzazioni-item-content-title {
    display: flex;
    align-items: center;
}

.autorizzazioni-item-content-title img {
    display: flex;
    align-items: center;
    margin: 0 1rem 0 0;
}

.highlight-section * {
    color: white;
}

.after-logo-section-content {
    padding: 0 0 3rem 0 !important;
}

.article-more-padding {
    margin-bottom: 1rem;
}

.white-section,
.alt-section,
.grey-section,
.strong-section {
    max-width: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4rem 0;
}

.white-section h3,
.alt-section h3 {
    text-transform: uppercase;
    font-size: 2rem;
}

.fullscreen-section-content h4 {
    margin-top: 1rem;
}

.white-section {
    background-color: white;
}

.alt-section {
    background-color: var(--alt-color);
}

.alt-section p,
.white-section p {
    color: var(--background-dark-color);
}

.dark-grey-section {
    background-color: var(--background-dark-color-lighter);
}

.dark-grey-section > * {
    color: white;
}

.dark-grey-section b {
    color: var(--primary-color) !important;
}

.dark-grey-section i {
    color: white;
}

.dark-grey-section .outside-link {
    color: white;
}

.dark-grey-section .outside-link:hover {
    color: var(--primary-color);
}

.project-logo {
    border-radius: 50%;
    max-width: 128px !important;
    max-height: 128px !important;
}

.rounded-logo {
    border-radius: var(--image-border-radius);
}

.centered {
    clear: both;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fullscreen-section-content {
    max-width: var(--page-width);
    width: 100%;
}

.shortcuts-list {
    width: 100%;
}

.section-content-landing {
    display: flex;
    justify-content: center;
}

.boolli-section > * {
    color: var(--background-dark-color);
}

.link-matrix {
    border-bottom: 3px solid #00ff41;
}

.link-matrix:hover {
    color: #00ff41 !important;
}

.link-dev-genius {
    border-bottom: 3px solid #2a9d8f;
}

.link-dev-genius:hover {
    color: #2a9d8f !important;
}

.link-basic {
    border-bottom: 3px solid #1c70c6;
}

.link-basic:hover {
    color: #1c70c6 !important;
}

/*#region Contact form */

.quick-contacts {
    margin: 2rem 0;
}

.quick-contacts ul {
    padding: 0;
    margin: 0;
}

.quick-contacts > h4 {
    text-align: center;
    padding: 0;
    margin: 0 0 1rem 0;
}

.quick-contacts ul li {
    list-style: none;
    margin: 0.5rem;
}

.quick-contacts a:hover {
    color: var(--primary-color-brighter);
}

/*#endregion*/

.shortcuts-list ul {
    margin-left: 0;
    display: grid;
    padding: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
}

.strong-section {
    background-color: var(--primary-color);
}

.shortcut-list ul li {
    height: 455px;
    color: var(--text-color);
}

.shortcuts-list-home img {
    max-width: 100%;
}

.shortcuts-list-title-container {
    display: flex;
    align-items: center;
}

.shortcuts-list-title-container img {
    margin: 0;
    border-radius: 10px 10px 0 0;
}

.shortcuts-list-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.shortcuts-list-content p {
    text-align: left;
}

.shortcuts-list-content h3 {
    text-align: left;
}

.shortcuts-list-home {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
}

.shortcuts-list li {
    list-style: none;
}

.shortcuts-list-home li {
    background-color: white;
    border-radius: 10px;
    color: var(--background-dark-color);
    display: flex;
    flex-direction: column;
    padding: 0;
}

.shortcuts-list-home li p {
    flex: 1;
}

.shortcuts-list-home a {
    text-align: center;
}

.shortcuts-list li:hover > a {
    transition: all 0.2s ease-in;
    opacity: 1;
}

.shortcuts-list-home li span {
    font-size: 1.2rem;
    font-weight: 900;
    padding-top: 1rem;
    display: block;
}

.shortcuts-list li p {
}

.shortcut-link {
    color: var(--primary-color);
}

.shortcuts-list i {
    color: var(--primary-color);
}

.sitemap-link {
    text-decoration: none;
    color: var(--background-dark-color);
}

.sitemap-link:hover {
    color: var(--primary-color);
}

.link-telegram {
    border-bottom: 3px solid #2ea5e2;
}

.link-telegram:hover {
    color: #2ea5e2;
}

.quick-contact-label {
    width: 14rem;
    display: inline-block;
    font-weight: 700;
}

.javascript-disabled-message {
    margin-top: var(--nav-height);
    display: none;
    background-color: var(--error-color);
    text-align: center;
    width: 100%;
    color: white;
    max-width: none;
}

/*#region Sitemap */

.sitemap-group {
    margin: 2rem;
}

.sitemap-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    max-width: 100%;
}

.sitemap-group-title {
    font-size: 1.3rem;
    font-weight: 700;
}

/*#endregion*/

.coloured-header {
    padding: 4rem 0 1rem 0;
    max-width: var(--page-width);
    width: 100%;
}

.homepage-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 0;
    min-height: 180px;
    justify-content: center;
}

.homepage-header > div {
    padding-left: 1rem;
}

.homepage-header h1 {
    text-transform: uppercase;
    color: white;
    font-size: 3rem;
}

.homepage-header h2 {
    color: white;
    font-size: 1.5rem;
}

.shortcut-list {
    display: flex;
    flex-wrap: wrap;
}

.shortcut-box {
    margin: 1rem;
}

.code-snippet,
code {
    border-radius: 5px;
    font-family: Consolas, Liberation Mono, Menlo, monospace !important;
    font-style: normal;
    background-color: var(--code-snippet-background-color);
    font-weight: 400;
}

pre {
    border-radius: 5px;
    padding: 1rem;
    font-family: Consolas, Liberation Mono, Menlo, monospace !important;
    font-style: normal;
    background-color: var(--code-snippet-background-color);
    font-weight: 400;
    overflow: scroll;
    overflow-y: hidden;
}

/*#region BreadCrumbs */

.breadcrumbs-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: white;
    font-weight: 300;
    width: 100%;
    max-width: var(--page-width);
}

.breadcrumbs-container ol {
    max-width: var(--page-width);
    padding: 0;
    list-style: none;
    width: 100%;
    color: var(--background-dark-color);
}

.breadcrumbs-container ol > li {
    display: inline;
    font-size: 0.8rem;
}

.breadcrumbs-container ol > li + li:before {
    font-family: 'custom-icons';
    padding: 0.5rem;
    content: '\e801';
    color: var(--background-dark-color);
}

.breadcrumbs-container ol > li > a {
    color: var(--background-dark-color);
    text-decoration: none;
}

.breadcrumbs-container ol > li > a:hover {
    color: var(--primary-color);
}

/*#endregion*/

.contact-title {
    text-align: center !important;
    padding: 1rem 0;
    text-transform: uppercase;
}

.list-without-dots li {
    list-style: none;
}

main > section > ul {
    list-style: none;
}

main > section > ul li::before {
    display: inline-block;
    content: '\2192';
    font-weight: bold;
    width: 2rem;
    font-size: 1.5rem;
    color: var(--primary-color);
}

.article-image-gallery {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.article-image-gallery img {
    max-height: 450px;
    border-radius: 20px;
    margin: 0.1rem;
}

/*#region Scrollbar */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: #6e6e67;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

::-webkit-scrollbar-track {
    background: var(--background-dark-color);
}

/*#endregion*/

#labelPrivacy {
    font-weight: 400;
}

/** Animazione immagini */

.show-on-scroll {
    opacity: 0;
    transform: translateX(-2rem);
    transition: all 1s ease-out;
    will-change: transform, opacity;
}

.is-visible {
    opacity: 1;
    transform: translateX(0rem);
}

a {
    text-decoration: none;
}

footer a,
footer i {
    font-size: 0.9rem;
    text-decoration: none;
    color: var(--text-over-background-dark);
}

footer a:hover {
    color: var(--primary-color-brighter);
}

footer i {
    text-decoration: none;
    color: var(--text-over-background-dark);
}

footer span {
    color: var(--text-over-background-dark);
}

.footer-link-contact > a,
.footer-link-contact > i {
    color: var(--secondary-color) !important;
}

.footer-link-contact > a:hover {
    color: var(--primary-color-brighter) !important;
}

.action-button-header-box {
    background-color: var(--primary-color);
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 1rem 0;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 900;
    transition: all ease-in-out 0.4s;
}

.action-button-header-box:hover {
    background-color: var(--primary-color-brighter);
    transition: all ease-in-out 0.4s;
}

.action-button,
.action-button-secondary,
.action-button-secondary-for-contact {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 1rem 2rem;
    font-weight: 900;
    border-radius: 100rem;
    text-decoration: none;
    margin: 0;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s;
}

.action-button:hover {
    text-decoration: none;
    transform: scale(1.1);
    transition: all ease-in-out 0.3s;
    background-color: var(--primary-color-brighter);
}

.action-button-secondary {
    background-color: white;
    color: black;
    border: 2px solid black;
}

.action-button-secondary:hover {
    background-color: var(--primary-color);
    text-decoration: none;
    color: white;
    border-color: white;
    transition: all ease-in-out 0.3s;
}

.action-button-secondary-for-contact {
    background-color: white;
    color: black;
    border: none;
}

.action-button-secondary-for-contact:hover {
    background-color: var(--secondary-color);
    text-decoration: none;
}

.action-button-for-services {
    cursor: pointer;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 200rem;
    border: 2px solid transparent;
    transition: all 0.2s ease-out;
    transition: all ease-in-out 0.3s;
}

.action-button-for-services i {
    transform: rotate(0deg);
    display: inline-block;
    transition: all 0.2s ease-in;
}

.action-button-for-services:hover {
    border-radius: 200rem;
    border: 2px solid var(--primary-color);
    transition: all 0.2s ease-in;
    color: var(--primary-color);
}

.action-button-for-services:hover i {
    transform: rotate(90deg);
    transition: all 0.2s ease-in;
}

.button-top-margin {
    margin-top: 2rem;
}

.action-button {
    background-color: var(--primary-color);
    color: white;
}

.link-icon {
    color: var(--background-dark-color);
    transform: scale(1);
    transition: all 0.2s ease-out;
}

.link-icon:hover {
    transform: scale(1.2);
    transition: all 0.2s ease-in;
}

.action-button i {
    color: white;
}

.section-content-with-text-image {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section-content-with-text-image > img {
    margin-right: 1rem !important;
}

/*#region Back to top */

.back-to-top-button {
    opacity: 0;
    bottom: 20px;
    position: fixed;
    right: 18px;
    z-index: -99;
    background-color: transparent;
    font-size: 3rem;
    color: #808080;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.5s ease;
    border: none;
}

.back-to-top-button:hover {
    opacity: 1;
    transition: all 0.2s ease;
    color: var(--primary-color);
}

.back-to-top-button-visible {
    z-index: 99;
    opacity: 0.5;
    transition: all 0.5s ease;
}

/*#endregion*/

.link-icon {
    border: none;
    display: inline-block;
}

.icon-small-for-footer {
    font-size: 1rem;
    margin: 0;
}

.icon-email,
.icon-telegram {
    margin-right: 2px;
}

.quick-contact-label .icon-linkedin {
    font-size: 1rem;
    margin: 0 2px 0 0;
}

.store-download-buttons-group {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    justify-content: center;
}

.store-download-buttons-group > * {
    margin: 0.2rem 0;
}

.menu-separator {
    border: 1px solid white;
    border-radius: 50px;
    width: 70px;
    margin: 1rem 0;
}

.header-separator {
    background-image: linear-gradient(
        to right,
        var(--gradient-first-color) 0%,
        var(--gradient-second-color) 51%,
        var(--gradient-third-color) 100%
    );
    border-radius: 50px;
    width: 70px;
    height: 3px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.singleCenteredImageSection {
    max-width: 500px;
    width: 100%;
    margin-top: 1rem;
    border-radius: 40px !important;
    height: auto;
    margin-bottom: 1rem;
    align-items: center;
}

.centered-image-container {
    display: flex;
    justify-content: center;
}

.section-separator,
.section-separator-inside-section {
    background-image: url('../images/other/pattern.png');
    align-self: center;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    width: 200px;
    height: 16px;
    margin-bottom: 2rem;
    margin-top: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.section-separator-for-overlay-menu {
    margin: 1rem auto !important;
}

.section-separator-inside-section {
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.section-separator-with-margin-from-header {
    margin-top: 3rem;
}

.section-content h2 {
    margin-bottom: 2rem;
}

.article-header {
    padding: 6rem 0 1rem 0;
    max-width: var(--page-width);
    width: 100%;
}

.article-header h1,
h2 {
    text-align: center;
}

.logo-download-buttons-container {
    display: flex;
    flex-direction: row;
    max-width: var(--page-width);
    width: 100%;
}

.logo-download-buttons-container img {
    margin: 0;
}

section a {
    text-decoration: underline;
    color: var(--text-color);
}

.morpheus-paragraph {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    clear: both;
}

.morpheus-paragraph p {
    display: inline-block;
    padding-bottom: 0;
}

.morpheus-paragraph a {
    border: none;
}

.morpheus-paragraph img {
    max-width: 500px;
    border-radius: 20px;
}

.section-centered-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.francesco-bonizzi-photo-caption {
    font-weight: bold;
    text-align: center;
}

#home-button-link {
    display: flex;
}

.homepage-article-image {
}

.servizi-header {
    width: 100%;
    position: relative;
    text-align: center;
    background-color: var(--background-dark-color);
}

.header-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    max-height: 25rem;
}

.header-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.6);
    padding: 1.2rem 2rem;
    border-radius: var(--image-border-radius);
}

.header-text h1 {
    font-size: 3rem;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.header-text h2 {
    color: white;
    text-align: center;
}

.immagini2x2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.immagini2x2 img,
.immagini1x2 img {
    width: 100%;
    margin: 0.5rem;
}

.immagini1x2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.notfound-buttons-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.notfound-buttons-content > * {
    padding: 0.5rem;
    font-size: 1.4rem;
}

input[type='text'],
input[type='email'],
input[type='tel'],
select,
textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

label {
    padding: 12px 12px 12px 0;
    display: inline-block;
}

.form-container {
    border-radius: 1rem;
    background-color: #f2f2f2;
    padding: 2rem;
    margin-bottom: 1rem;
}

.form-container h3 {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
}

.col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
}

.col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
}

/* Clear floats after the columns */
.form-container-row:after {
    content: '';
    display: table;
    clear: both;
}

.form-button-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

#contact-submit {
    border: none;
    cursor: pointer;
    margin-top: 1rem;
}

.download-my-vcard {
    display: inline-block;
}

.contacts-page {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 1rem;
}

.conferma-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.conferma-form-container img {
    width: 100%;
    max-width: 300px;
    height: auto;
}
