@media only screen and (max-width: 1300px) {

    :root {
        --nav-height: 4.4rem;
    }

    html {
        /* Per rem */
        font-size: 15px;
    }

    #hamburger-menu-button {
        display: block;
    }

    #top-nav {
        display: none;
    }

    #top-bar-email {
        display: none;
    }

    .logo-download-buttons-container {
        flex-direction: column;
        align-items: center;
        align-self: center;
    }

    .project-logo {
        margin-bottom: 0.8rem !important;
    }

    .vertical-image {
        max-width: 100% !important;
    }

    #overlay-menu-input:checked ~ #overlay-menu-button span:after {
        transform: rotate(-45deg) translate(5px, -5px);
    }

    .morpheus-buttons {
        transform: scale(0.8);
    }

    .morpheus-blue-pill,
    .morpheus-red-pill {
        width: 15rem;
    }

    #overlay-menu * {
        font-size: 1.1rem !important;
    }

    .contact-button-phone-small {
        display: grid !important;
    }

    .contact-button-phone-large {
        display: none;
    }

    .button-scarica-contatto-small {
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }
    
    .button-scarica-contatto-large {
        display: none;
    }

    #overlay-menu .section-separator {
        width: 70px;
        height: 10px;
    }

    .vertical-buttons {
        padding-bottom: 1rem;
    }


    .shortcuts-list ul {
        display: flex;
        flex-direction: column;
    }

    .shortcuts-list li {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }

    section {
        
    }

    .shortcuts-list-home {
        padding: 0 !important;
    }

    .shortcuts-list-home a {
        align-self: stretch;
        text-align: center;
    }

    .left-image {
        margin-right: 0;
    }

    .section-content-with-text-image {
        flex-direction: column;
    }

    .section-content-with-text-image > img {
        margin-right: 0 !important;
    }

    .intermediate-article-image {
        border-radius: 15px !important;
    }

    .section-content,
    .fullscreen-section-content {
        padding: 0 1rem !important;
    }

    .shortcuts-list-title-container {
        align-self: center;
    }

    #overlay-menu > * {
        font-size: 1rem !important;
    }

    #overlay-menu ul li {
        text-align: center;
    }

    .breadcrumbs-section {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .header-with-image {
        flex-direction: column;
        padding-bottom: 0;
    }

    .header-with-image h3 {
        margin-top: 2rem;
        margin-bottom: 0 !important;
        margin-right: 1rem;
        margin-left: 1rem;
    }

    .header-content img {
        margin-left: 0.5rem;
        max-height: 90px;
        max-width: 90px;
    }

    .footer-infos-container ul li {
        margin-right: 0;
    }

    .contactNotFullScreenSection {
        border-radius: 10px;
        margin: 0 0.5rem;
        padding: 0 0 2rem 0;
    }


    .action-button, .action-button-secondary, .action-button-secondary-for-contact {
        font-size: 1rem;
        text-align: center;
        width: 100%;
    }

    footer ul {
        flex-direction: column;
    }

    .top-contact-bar {
        padding: 0;
        height: 3rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }

    .top-contact-bar :first-child {
        flex: none;
    }

    .top-contact-bar a {
        text-align: center;
    }

    main {
        /* nav-height + height di top contact bar */
        margin-top: 7.4rem;
    }

    .servizi-header {
        height: 12rem;
    }

    .contactNotFullScreenSection {
        padding: 2rem 0;
    }

    .main-homepage .contact-box {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    #overlay-menu-button {
        right: 0;
        top: 3.8rem;
        padding: 16px 11px;
    }

    .shortcuts-list-content h3 {
        text-align: center;
    }

    .grey-section {
        padding-top: 0;
        padding-bottom: 0;
    }

    .header-text h1 {
        font-size: 1.8rem;
        margin-bottom: 0.5rem;
    }
    
    .header-text h2 {
        font-size: 1rem;
        font-weight: 900;
    }

    #second-subtitle-header {
        display: none;
    }

    #overlay-menu-text {
        font-size: 0.8rem;
    }

    #overlay-menu-button span,
    #overlay-menu-button span:before,
    #overlay-menu-button span:after {
        height: 3px;
    }

    #overlay-menu-button span:before {
        top: -8px;
    }

    #overlay-menu-button span:after {
        top: 8px;
    }

    .logo-gallery-collaborations > div {
        width: auto;
    }

    .logo-gallery {
        justify-content: space-around;
    }

    .shortcuts-list {
        padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        text-align: center;
        padding: 0 0.8rem;
    }

    .fullscreen-section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .fullscreen-section-content h4 {
        margin-top: 1rem;
    }

    .section-separator {
    //    margin: 1.4rem 0;
    }

    .card,
    .small-card {
        align-self: stretch;
    }

    .open-source-project-card-item-title h4,
    .open-source-project-card-item-title h3 {
        margin: 0;
        text-align: left;
    }

    .bottom-menu-section div {
        margin: 0;
        width: 100%;
    }

    section p {
        padding: 1rem;
    }

    .bottom-menu-section img {
        margin-bottom: 1rem;
    }

    .bottom-menu-section {
        padding: 1rem 0rem;
    }

    footer li {
        text-align: center;
    }

    .article-header h1 {
        text-align: center;
    }

    .breadcrumbs-container {
        padding: 0 1rem;
    }

    .store-download-buttons-group {
        margin-left: 0 !important;
    }

    section {
        max-width: none;
        width: auto;
        align-self: stretch;
    }
    
    .quick-contact-label {
        width: auto;
    }

    .article-header {
        padding: 3rem 0 1rem 0;
    }

    iframe {
        width: 100%;
    }

    section img {
        max-width: 350px;
        height: auto;
        width: 100%;
    }

    .card {
        margin: 1rem;
    }

    .col-25, .col-75, #contact-submit {
        width: 100%;
        margin-top: 0;
      }

    #contact-submit {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 1rem;
    }

    .top-contact-bar > * {
        font-weight: bold;
    }

    .immagini1x2 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .immagini2x2 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
